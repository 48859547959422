<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>青少年模式申诉</span>
        </div>
        <div class="content">
            <div>
                若您需要重置时间锁或青少年模式的密码，请您发送邮件至 <span class="email">{{appealInfo.email}}</span>，主题命名为【电报号+青少年模式密码重置】。
            </div>
            <div class="re_flex number">
                您的电报号为:{{appealInfo.sn}}
                <img :data-clipboard-text="appealInfo.sn" data-clipboard-target="#copy"
                    @click="copy(appealInfo.sn)" src="@assets/copy.png" alt="">
            </div>
            <div>
                邮件中请您上传本人手持身份证的照片，并确保身份证清晰可辨。您的资料仅用于密码重置申诉，开水壶不会泄露您的个人信息，并会尽快为您处理。
            </div>
        </div>
    </div>
</template>

<script setup>
import { appealPage } from './api.js'
import { ref, onMounted } from 'vue';
import useClipboard from 'vue-clipboard3';
import { showToast } from 'vant';
const appealInfo = ref({});
const { toClipboard } = useClipboard()
const goBack = () => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        appJsImp.goBack()
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage('goBack')
    }
}
const copy = async (text) => {
    try {
        await toClipboard(text)
        showToast('复制成功')
    } catch (e) {
        console.error(e)
    }
}
onMounted(() => {   
    appealPage().then(res => {
        console.log(res);
        appealInfo.value = res.data;
    })
})
</script>

<style lang="less" scoped>
.bg {
    padding-top: 50px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .content{
        padding: 24px;
        color: #333;
        text-align: left;
        .number {
            align-items: baseline;
            margin: 16px 0;
        }
        img{
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
}
</style>