import RSA from './rsa.js';
let CryptoJS = require('./crypto-js.js');
var RSA_PUBLIC =
  '-----BEGIN PUBLIC KEY-----\
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGj8WNUSzCn0rHqfIXk9XPdJp60u\
    JnM+mVZVxY1lZmUCVA7t3eJZ0R0z1hUHkVb51eryDZcsz0QLSav3cmQv00ullK18\
    8aOs2SXZe6rcQf6XmOsVBqgADkrN+WePZJmb5Fr0NUkQ/sr7+R71cDZ87Y9QKm99\
    8BFOiWoEGxWWvDsTAgMBAAE=\
        -----END PUBLIC KEY-----';

import axios from 'axios';
var surl = window.location.href;
if(window.location.href.indexOf('/wechat') > -1 && (location.hostname == 'localhost' || location.hostname == '192.168.1.60')){
  surl = 'http://aitest.lanlingcb.com//#/wechat?postId=7256&env=test'
}else if(window.location.href.indexOf('/register') > -1 && (location.hostname == 'localhost' || location.hostname == '192.168.1.60')){
  surl = 'http://aidev.lanlingcb.com/#/register?user_id=32742&version=2.4.2&env=test&os=wxapplet_happy_group&token=bearer%20bd80e7b4-785e-45c2-8e94-14c6f5f788f3'
}else if(window.location.href.indexOf('/appleTree') > -1){
  surl = 'http://aidev.lanlingcb.com/?user_id=32588&owner=32588&version=2.7.1&device=h5&os=h5'
}else if(location.hostname == 'localhost' || location.hostname == '192.168.1.60'){
  surl = 'http://aitest.lanlingcb.com/#/home?user_id=32250&version=2.7.0&os=wxapplet_happy_group&token=bearer b5078f0a-c17a-47fa-b6dc-52c1b3db13c9'
}
// surl = 'http://aitest.lanlingcb.com/#/teenageMode/appeal?user_id=31701&env=1&token=bearer%2097bf26ff-4033-47bf-b20d-5fae2e41517e&version=272&os=android'
var obj = {};
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
  obj[temp[0]] = temp[1]
}
var protocol = window.location.protocol
console.log(process.env.NODE_ENV)
var baseURL = ''
if (process.env.NODE_ENV == 'test') {
    baseURL = protocol + "//kshtest.lanlingcb.com";
} else if (process.env.NODE_ENV == 'staging') {
    baseURL = protocol + "//preuser.kaishuihu.com";
} else if (process.env.NODE_ENV == 'production') {
    baseURL = protocol + "//user.kaishuihu.com";
} else if (process.env.NODE_ENV == 'development') {
    baseURL = protocol + "//kshtest.lanlingcb.com";
} else {
    baseURL = protocol + "//user.kaishuihu.com";
}
obj.baseURL = baseURL
// baseURL = 'http://kshtest.lanlingcb.com'
// 创建axios实例
const instance = axios.create({
    baseURL: baseURL, // 根据您的实际情况设置基础URL
    timeout: 30000 // 设置请求超时时间
});
instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";


// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        if (response.status === 200) {
            //响应成功后
            return Promise.resolve(response);
        } else if (response.data.code === 401) {
            alert('请重新登录');
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        // 对响应错误做点什么
        if (error) {
            console.log(error)
            switch (error.code) {
            case 401:
                alert('请重新登录')
            }
        }
    }
);

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function post({ url, data = {} }) {
    if(window.location.href.indexOf('/register') > -1){
        data.inviteUserId = obj.user_id
        if(process.env.VUE_APP_API_URL == 'https://llcbdev.lanlingcb.com/dsgc'){
            data.env = 'dev'
        }else if(process.env.VUE_APP_API_URL == 'https://kshtest.lanlingcb.com/dsgc'){
            data.env = 'test'
        }else if(process.env.VUE_APP_API_URL == 'https://prellcb.kaishuihu.com/dsgc'){
            data.env = 'gray'
        }else if(process.env.VUE_APP_API_URL == 'https://llcb.kaishuihu.com/dsgc'){
            data.env = 'prod'
        }
        data.code = obj.user_id
        console.log(data,'data')
    }

    let formData = new FormData();
    Object.assign(data, obj);
    var timestamp = new Date().getTime();
    var randomStr = CryptoJS.MD5(timestamp.toString()).toString();
    var privateKey = RSA.encrypt(randomStr, RSA.getPublicKey(RSA_PUBLIC));
    instance.defaults.headers.post['privateKey'] = privateKey;
    var a = Date.now();
    let ocj = {
        sendTime: a,
        version: obj.version,
        os: 'wxapplet_happy_group',
        userId: obj.user_id,
        device:'device',
    }
    Object.assign(data, ocj);
    var encrypt = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Utf8.parse(randomStr),
        {
          iv: CryptoJS.enc.Utf8.parse(randomStr.substr(0, 16)),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
    );
    var sign = encrypt.toString(); //加密后的数据
    formData.append('sign', sign);
    return new Promise((resolve, reject) => {
        instance
          .post(url, formData)
          .then((res) => {
            // console.log('请求地址', url, '参数', data, '结果：', res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
}